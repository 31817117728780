.cardOverlay::before {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    content: "";
    background: #1c1d25;
    opacity: .7;
    transition: opacity .3s ease;
    z-index: 1;
    box-shadow: 0 20px 80px 0 rgb(0 0 0 / 45%);
    border-radius: 1.5rem;
}

.cardSize {
    width: 650px;
    
}